// We need this for the date picker to work with our backend.
// If not loaded dates like 23/01/2025 are presented to the server in a format that cannot be parsed.

document.addEventListener("turbo:load", function () {
    $.datepicker.regional['nl'] = {
        closeText: 'Sluiten',
        prevText: 'Vorige',
        nextText: 'Volgende',
        currentText: 'Vandaag',
        monthNames: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni',
            'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun',
            'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
        dayNamesShort: ['Zon', 'Maa', 'Din', 'Woe', 'Don', 'Vri', 'Zat'],
        dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        weekHeader: 'Wk',
        dateFormat: 'dd-mm-yyyy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };

    $.datepicker.setDefaults($.datepicker.regional['nl'])
});